var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.propertyDisclosure
        ? _c(
            "v-alert",
            {
              staticClass: "mt-3",
              attrs: {
                border: "top",
                color: "accent",
                dark: "",
                icon: "mdi-message-bulleted",
                prominent: ""
              }
            },
            [
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.propertyDisclosure) }
              })
            ]
          )
        : _vm._e(),
      _vm.sections["property-portland-city-notices"]
        ? _c(
            "v-card",
            { staticClass: "px-3 pt-2 pb-4" },
            [_c("portland-city-notices")],
            1
          )
        : _vm._e(),
      _vm.sections["property-portland-city-disclosure"]
        ? _c(
            "v-alert",
            {
              staticClass: "mt-3",
              attrs: {
                border: "top",
                color: "accent",
                dark: "",
                icon: "mdi-city",
                prominent: ""
              }
            },
            [
              _c("div", { attrs: { id: "pca-disclosure" } }, [
                _c("h4", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "applications.portland-city-application-disclosure-title"
                        )
                      ) +
                      " "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "applications.portland-city-application-disclosure"
                        )
                      ) +
                      " "
                  )
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }