<template>
    <div>
        <v-alert
            class="mt-3"
            border="top"
            color="accent"
            dark
            icon="mdi-message-bulleted"
            v-if="propertyDisclosure"
            prominent
        >
            <p v-html="propertyDisclosure"></p>
        </v-alert>

        <v-card
            v-if="sections['property-portland-city-notices']"
            class="px-3 pt-2 pb-4"
        >
            <portland-city-notices></portland-city-notices>
        </v-card>
        <v-alert
            class="mt-3"
            border="top"
            color="accent"
            dark
            icon="mdi-city"
            v-if="sections['property-portland-city-disclosure']"
            prominent
        >
            <div id="pca-disclosure">
                <h4>
                    {{
                        $t(
                            "applications.portland-city-application-disclosure-title"
                        )
                    }}
                </h4>
                <p>
                    {{
                        $t("applications.portland-city-application-disclosure")
                    }}
                </p>
            </div>
        </v-alert>
    </div>
</template>
<script>
export default {
    components: {
        PortlandCityNotices: () => import("./PortlandCityNotices.vue"),
    },
    name: "property-disclosures",
    props: {
        disclosure: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        sections: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        propertyDisclosure() {
            return this.disclosure[this.$i18n.locale];
        },
    },
};
</script>

<style scoped>
</style>